<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import Skeleton from "@/view/front/components/skeleton";
import moment from "moment";
import Swal from "sweetalert2";

/**
 * page-jobs-new component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      user:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user"))
          : {},
      profile:
        localStorage.getItem("profile") !== null
          ? JSON.parse(localStorage.getItem("profile"))
          : {},
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      keywords: "",
      email: "",
      location: "",
      data: [],
      experiences: [],
      selected_job_experience: [],
      success: false,
    };
  },
  components: {
    Navbar,

    Switcher,
    // Skeleton,
    Footer,
  },
  mounted() {
    this.email = this.auth ? this.user.email : "";
    this.location = this.auth ? this.profile.city : "";
    this.getJobCareerLevel();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getJobCareerLevel() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((response) => {
        this.experiences = response.data;
      });
    },
    submitJobAlert() {
      this.success = true;
      if (!this.keywords) {
        Swal.fire({
          title: "",
          text: "Please enter keyword!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (
        !this.selected_job_experience ||
        this.selected_job_experience == null ||
        this.selected_job_experience == ""
      ) {
        Swal.fire({
          title: "",
          text: "Please select job experience!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      if (!this.location) {
        Swal.fire({
          title: "",
          text: "Please select job location!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.success = false;
        return;
      }
      axios
        .post(`${API_URL}/job-alert`, {
          keywords: this.keywords,
          location: this.location,
          career_level: this.selected_job_experience,
          email: this.email,
        })
        .then((res) => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
          if (res.data === "200") {
            Swal.fire({
              title: "",
              text: "Job alert has been saved.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            Swal.fire({
              title: "",
              text: "Some thing went rong",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
        });
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100 mt-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 pagech1">
            <div class="blog_header pl-0">
              <div class="col-lg-12 p-0 w-100">
                <div class="blog_back">
                  <i class="fas fa-long-arrow-alt-left p-1"></i>
                  <router-link to="/career-resources"
                    >Back to blog list</router-link
                  >
                </div>
              </div>
              <h2 class="m_title">Starting A New Job</h2>
              <span class="m_title">by: job4u.pk (3 min read)</span>
            </div>

            <div class="p_about2">
              You’ve cleared the interview, made it past the hiring processes,
              and are now in the promised land – hired into your new role. So
              now what? There’s a lot of uncertainty that comes with a new job:
              new people, a new environment, and a new set of responsibilities.
              This article has tips and tricks to make sure you excel in your
              new role and make the most out of the new opportunity you’ve
              gotten.
            </div>

            <div class="p_about2">
              Your first few weeks may well be confusing, so it is best to get
              to know your coworkers early. Who occupy the spaces closest to
              you? Who share a role most similar to yours? Making bonds with
              your coworkers is a great way to find a place in your new office’s
              hierarchy, and you can turn to them if you have any trouble,
              rather than going straight to your boss or manager for simple
              problems. As a bonus, this also solidifies your good impression in
              the management’s eyes, which should be a key goal during your
              first weeks at your new job.
            </div>

            <div class="p_about2">
              Having said that, however, it is important to find the balance
              between creating a good impression and overstretching yourself.
              Don’t take on more than you can handle in an attempt to showcase
              yourself in the best light, because the pace you set for yourself
              now determines the pace that will continue throughout your tenure
              in this role. Feel free to volunteer or opt for things you can
              easily do, but don’t overcommit when something seems undoable, and
              don’t be afraid to (tactfully) say no.
            </div>

            <div class="p_about2">
              One of the best things you can do for yourself in a new job is to
              spend the first couple of weeks immersing yourself into the new
              organization. You can get a thorough understanding of how the
              company, your job, and your team work before making any
              suggestions or sharing input, and this puts you in a more secure
              position to assume your role and do these things more comfortably
              a little while down the line.
            </div>

            <div class="p_about2">
              When you start a new job, it’s only natural that you will make
              mistakes, so give yourself time to learn, and don’t be afraid to
              ask questions. Your employer(s) hired you with the understanding
              that there would be a learning period, and your best bet is to use
              that to learn; ask questions, observe, participate where you can,
              and ask for feedback on work you do. Feedback is how you can
              assess what’s working well, what you need to improve, and what the
              company you’re working for appreciates so, in short, feedback from
              management or your superiors is your measure of initial success in
              your new role.
            </div>

            <div class="p_about2">
              Be careful with many demands early on in your employment. Of
              course, if you’re not being given what you’re due, you have a
              right to demand it, but with this point, we’re talking about
              scheduling changes, handling of tasks, and other similar items.
              Spend some time doing your job to earn trust and build a reliable
              reputation, and you’ll find much more flexibility within your
              role.
            </div>

            <div class="p_about2">
              We all need a little help sometimes, but with these tips, you're
              sure to start off your new job on the right foot.
            </div>
            <br />
            <!-- <hr> -->
          </div>
          <div class="col-lg-4 col-md-4 mt-3">
            <div class="card">
              <div class="back_overlay">
                <div class="row">
                  <div class="col-lg-6 col-10">
                    <h2 class="">want a great job taillard to your skills ?</h2>
                    <p class="">
                      save time and find higher quality jobs by signing up to
                      job4u.pk
                    </p>
                    <button type="submit" class="btn btn-default join_btn mb-6">
                      <a href="/job-seeker-register">Join job4u.pk now! </a>
                    </button>
                  </div>
                  <div class="col-lg-6 col-2 cv_image_bg">
                    <!-- <img src="/images/carer_resorses/CV.png" alt=""> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-5">
              <div class="card-headerchch">Create a job alert!</div>
              <div class="card-body p-5">
                <form>
                  <div class="form-group">
                    <label class="labal_style" for="kyeword"
                      >keywords <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="keywords"
                      class="form-control form-control-sm serach_i_ch"
                      id="kyeword"
                      placeholder="Enter Kyewords"
                    />
                  </div>

                  <div class="m_title pt-1 labal_style">
                    CAREER LEVELS <span style="color: red">*</span>
                  </div>
                  <div class="" style="">
                    <ul class="mt-2">
                      <li v-for="(item, index) in experiences" :key="index">
                        <input
                          type="checkbox"
                          :id="item.career_level"
                          :name="item.career_level"
                          class="custom-control-input"
                          :value="item.career_level"
                          v-on:click="_jobCareerSelection($event)"
                        /><label
                          class="custom-control-label m_title"
                          :for="item.career_level"
                          style="font-size: 13px"
                          >{{ item.career_level }}</label
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputlocalisation1"
                      >Job Location</label
                    >
                    <input
                      type="text"
                      v-model="location"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputlocalisation1"
                      placeholder="localisation"
                    />
                  </div>
                  <div class="form-group">
                    <label class="labal_style" for="exampleInputYouremail1"
                      >Your email<span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      v-model="email"
                      class="form-control form-control-sm serach_i_ch"
                      id="exampleInputYouremail1"
                      placeholder="Youremail"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="card-headerch_btn bg_blue_ch"
                      @click="submitJobAlert()"
                      :class="
                        success ? 'spinner spinner-light pinner-right' : ''
                      "
                    >
                      Create job alert!
                    </button>
                    <!-- <div
                      v-if="success"
                      class="alert ml-5"
                      style="
                        background-color: #d4edda;
                        border-color: #d4edda;
                        color: #155724;
                      "
                      role="alert"
                    >
                      Form Submited
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
